import {config, dom, library} from '@fortawesome/fontawesome-svg-core'
import {faBan, faBullhorn, faCalendarDays, faCheck, faCheckCircle, faClockRotateLeft, faCog, faDesktop, faDivide, faDumbbell, faEdit, faExclamationTriangle, faExternalLinkAlt, faFileExcel, faFill, faHandsHelping, faHourglassHalf, faMapMarkerAlt, faNewspaper, faPersonChalkboard, faPlus, faSearch, faSitemap, faTimesCircle, faTrash, faTrophy, faUser, faUsers, faVideo} from '@fortawesome/free-solid-svg-icons'

config.mutateApproach = 'sync';

library.add(
    faCheckCircle,
    faCog,
    faDesktop,
    faEdit,
    faExclamationTriangle,
    faExternalLinkAlt,
    faMapMarkerAlt,
    faNewspaper,
    faPlus,
    faSearch,
    faTimesCircle,
    faTrash,
    faTrophy,
    faUser,
    faUsers,
    faCheck,
    faBan,
    faFileExcel,
    faVideo,
    faHourglassHalf,
    faHandsHelping,
    faSitemap,
    faDivide,
    faFill,
    faClockRotateLeft,
    faCalendarDays,
    faDumbbell,
    faBullhorn,
    faPersonChalkboard
);

dom.watch();