import {Controller} from "@hotwired/stimulus"

export class TournamentAPNController extends Controller {
    static targets = ["category", "competition", "minAPN", "maxAPN"]
    MIN_APNS       = {
        starter: 1,
        mixed_starter: 1,
        advanced: 1,
        mixed_advanced: 2,
        expert: 3.5,
        elite: 3.5
    }
    MAX_APNS       = {
        starter: 2.5,
        mixed_starter: 3,
        advanced: 4.5,
        mixed_advanced: 8,
        expert: 8,
        elite: 8
    }

    get minAPN() {
        return this.MIN_APNS[this.getAPNName()] || 0
    }

    get maxAPN() {
        return this.MAX_APNS[this.getAPNName()] || 0
    }

    connect() {
        this.setMinMax()
    }

    updateAPNInputs() {
        this.minAPNTarget.value = this.minAPN
        this.maxAPNTarget.value = this.maxAPN
        this.setMinMax()
    }

    getAPNName() {
        if (this.isMixed())
            return "mixed_" + this.categoryTarget.value.toLowerCase()
        else
            return this.categoryTarget.value.toLowerCase()
    }

    isMixed() {
        return (this.competitionTarget.value.toLowerCase() === "mixed")
    }

    setMinMax() {
        if (this.minAPN < 1 || this.maxAPN < 1) {
            this.minAPNTarget.setAttribute("readonly", "readonly")
            this.maxAPNTarget.setAttribute("readonly", "readonly")
            this.minAPNTarget.nextSibling.textContent = "APN für diese Kombination ist nicht definiert"
            this.maxAPNTarget.nextSibling.textContent = "APN für diese Kombination ist nicht definiert"
            return
        }
        this.minAPNTarget.removeAttribute("readonly")
        this.minAPNTarget.setAttribute("min", this.minAPN)
        this.minAPNTarget.setAttribute("max", this.maxAPN)
        this.maxAPNTarget.removeAttribute("readonly")
        this.maxAPNTarget.setAttribute("min", this.minAPN)
        this.maxAPNTarget.setAttribute("max", this.maxAPN)
        this.minAPNTarget.nextSibling.textContent = `Minimum APN muss zwischen ${this.minAPN} und ${this.maxAPN} liegen`
        this.maxAPNTarget.nextSibling.textContent = `Maximum APN muss zwischen ${this.minAPN} und ${this.maxAPN} liegen`
    }
}
